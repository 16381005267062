var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"hp-card-1 hp-upgradePlanCardOne hp-upgradePlanCardOne-bg"},[(this.$store.state.themeConfig.theme == 'dark')?_c('div',{staticClass:"position-absolute w-100 h-100",style:({
      top: 0,
      left: 0,
      backgroundImage:
        'url(' +
        require('@/assets/img/dasboard/analytics-payment-bg-dark.png') +
        ')',
      backgroundSize: 'cover',
      backgroundPosition: 'right center',
      zIndex: -1,
    })}):_c('div',{staticClass:"position-absolute w-100 h-100",style:({
      top: 0,
      left: 0,
      backgroundImage:
        'url(' +
        require('@/assets/img/dasboard/analytics-payment-bg.svg') +
        ')',
      backgroundSize: 'cover',
      backgroundPosition: 'right center',
      zIndex: -1,
    })}),_c('b-row',{staticClass:"mt-8",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('b-row',{attrs:{"align-v":"center","align-h":"between"}},[_c('b-col',{staticClass:"flex-grow-1"},[_c('h4',{staticClass:"mb-8"},[_vm._v("Get exclusive discounts for any payment method")]),_c('p',{staticClass:"hp-p1-body mb-0"},[_vm._v("by upgrading your plan to premium")])]),_c('b-col',{staticClass:"hp-flex-none w-auto"},[_c('b-button',{staticClass:"mt-16 mt-sm-0 bg-white text-primary hp-bg-dark-primary-1 hp-border-color-dark-primary-1 hp-text-color-dark-0",attrs:{"variant":"none"}},[_c('span',[_vm._v("Upgradge Now")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }